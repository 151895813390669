import { default as _91_46_46_46slug_93LUQlSEi5quMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/[...slug].vue?macro=true";
import { default as _91patch_93wSMl06r2wtMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/blog/[patch].vue?macro=true";
import { default as indexBICEhR5T6AMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/blog/index.vue?macro=true";
import { default as historyFMJ3W6cetZMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/breeding/history.vue?macro=true";
import { default as indexNrIiZLkjruMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/breeding/index.vue?macro=true";
import { default as _91job_93pDwxHkTD0qMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/career/[job].vue?macro=true";
import { default as indexhGEJGWFN4uMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/career/index.vue?macro=true";
import { default as index1Zu2NUSgogMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/change-log/index.vue?macro=true";
import { default as crafting3GcQ4PuPgLMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/crafting.vue?macro=true";
import { default as discordkHVQfpBcR0Meta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/discord.vue?macro=true";
import { default as downloadTbKJbS00vbMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/download.vue?macro=true";
import { default as indexg7QS5uwxEWMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/draft-blog/index.vue?macro=true";
import { default as empireW5784SGvS9Meta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/empire.vue?macro=true";
import { default as indexRha12YAQhNMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/game/index.vue?macro=true";
import { default as indexQoFuit2U32Meta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/index.vue?macro=true";
import { default as inventory9j3jnCVilUMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/inventory.vue?macro=true";
import { default as _91itemId_930Wh52R0wm1Meta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/items-nft/[itemId].vue?macro=true";
import { default as link_45tmazjivXQwlAIMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/link-tma.vue?macro=true";
import { default as linkseRRefGzKcCMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/links.vue?macro=true";
import { default as indexK45e6oo1t1Meta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/marketplace/index.vue?macro=true";
import { default as _91itemId_93IlT4IJNAsFMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/marketplace/items-nft/[itemId].vue?macro=true";
import { default as partnershipRuzFPDZaXZMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/partnership.vue?macro=true";
import { default as referral_45qrcodewrnTlQPjHVMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/planet-invasion/referral-qrcode.vue?macro=true";
import { default as signupjDT6BcRGp9Meta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/planet-invasion/signup.vue?macro=true";
import { default as _91tokenid_93n55Ap9cd5yMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/playermon/[tokenid].vue?macro=true";
import { default as privacyEnXCPPgJfaMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/privacy.vue?macro=true";
import { default as _91wallet_address_93BrvIzGSAMFMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/profile/[wallet_address].vue?macro=true";
import { default as indexrklN7BC4mEMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/profile/index.vue?macro=true";
import { default as leaderboardHpAEKukxRJMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/pve-game/leaderboard.vue?macro=true";
import { default as pym_45to_45solanaaOriXSN8L0Meta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/pym-to-solana.vue?macro=true";
import { default as roadmapyKsgbX2ez8Meta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/roadmap.vue?macro=true";
import { default as index0UXTvAzpVMMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/settings/index.vue?macro=true";
import { default as verify_45email_45address4aUEh6ObeQMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/settings/verify-email-address.vue?macro=true";
import { default as verifyIMzoVuFSW6Meta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/settings/verify.vue?macro=true";
import { default as _91category_939NdP5MFhvWMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/shops/[category].vue?macro=true";
import { default as spaceden_45christmas_45decorative_45itemswXN8HyjfhaMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/shops/spaceden-christmas-decorative-items.vue?macro=true";
import { default as spaceden_45lunar_45new_45year_45decorative_45items5goLA7fV8VMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/shops/spaceden-lunar-new-year-decorative-items.vue?macro=true";
import { default as _91itemId_93LzTS12GrSWMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/space-box/[itemId].vue?macro=true";
import { default as stakinghFTXo6yu2aMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/staking.vue?macro=true";
import { default as team9Mtr7KRjuRMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/team.vue?macro=true";
import { default as termszp6zsW7pu7Meta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/terms.vue?macro=true";
import { default as testnetPTz3PtSHwpMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/testnet.vue?macro=true";
import { default as indextumcaR0eTaMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/tokenomics/index.vue?macro=true";
import { default as list9Ezd4xTroFMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/tokenomics/list.vue?macro=true";
import { default as _91name_93DQliYIPVFKMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/wiki/enemy/[name].vue?macro=true";
import { default as indextMchkNdPCWMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/wiki/enemy/index.vue?macro=true";
import { default as _91name_93L8ykTmKpR7Meta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/wiki/playermon/[name].vue?macro=true";
import { default as indexZYk588FGqQMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/wiki/playermon/index.vue?macro=true";
import { default as spaceden_45decorative_45itemsvfTK3b8XluMeta } from "/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/wiki/spaceden-decorative-items.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/[...slug].vue")
  },
  {
    name: "blog-patch",
    path: "/blog/:patch()",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/blog/[patch].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/blog/index.vue")
  },
  {
    name: "breeding-history",
    path: "/breeding/history",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/breeding/history.vue")
  },
  {
    name: "breeding",
    path: "/breeding",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/breeding/index.vue")
  },
  {
    name: "career-job",
    path: "/career/:job()",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/career/[job].vue")
  },
  {
    name: "career",
    path: "/career",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/career/index.vue")
  },
  {
    name: "change-log",
    path: "/change-log",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/change-log/index.vue")
  },
  {
    name: "crafting",
    path: "/crafting",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/crafting.vue")
  },
  {
    name: "discord",
    path: "/discord",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/discord.vue")
  },
  {
    name: "download",
    path: "/download",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/download.vue")
  },
  {
    name: "draft-blog",
    path: "/draft-blog",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/draft-blog/index.vue")
  },
  {
    name: "empire",
    path: "/empire",
    meta: empireW5784SGvS9Meta || {},
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/empire.vue")
  },
  {
    name: "game",
    path: "/game",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/game/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/index.vue")
  },
  {
    name: "inventory",
    path: "/inventory",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/inventory.vue")
  },
  {
    name: "items-nft-itemId",
    path: "/items-nft/:itemId()",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/items-nft/[itemId].vue")
  },
  {
    name: "link-tma",
    path: "/link-tma",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/link-tma.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: linkseRRefGzKcCMeta || {},
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/links.vue")
  },
  {
    name: "marketplace",
    path: "/marketplace",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/marketplace/index.vue")
  },
  {
    name: "marketplace-items-nft-itemId",
    path: "/marketplace/items-nft/:itemId()",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/marketplace/items-nft/[itemId].vue")
  },
  {
    name: "partnership",
    path: "/partnership",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/partnership.vue")
  },
  {
    name: "planet-invasion-referral-qrcode",
    path: "/planet-invasion/referral-qrcode",
    meta: referral_45qrcodewrnTlQPjHVMeta || {},
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/planet-invasion/referral-qrcode.vue")
  },
  {
    name: "planet-invasion-signup",
    path: "/planet-invasion/signup",
    meta: signupjDT6BcRGp9Meta || {},
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/planet-invasion/signup.vue")
  },
  {
    name: "playermon-tokenid",
    path: "/playermon/:tokenid()",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/playermon/[tokenid].vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/privacy.vue")
  },
  {
    name: "profile-wallet_address",
    path: "/profile/:wallet_address()",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/profile/[wallet_address].vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/profile/index.vue")
  },
  {
    name: "pve-game-leaderboard",
    path: "/pve-game/leaderboard",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/pve-game/leaderboard.vue")
  },
  {
    name: "pym-to-solana",
    path: "/pym-to-solana",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/pym-to-solana.vue")
  },
  {
    name: "roadmap",
    path: "/roadmap",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/roadmap.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/settings/index.vue")
  },
  {
    name: "settings-verify-email-address",
    path: "/settings/verify-email-address",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/settings/verify-email-address.vue")
  },
  {
    name: "settings-verify",
    path: "/settings/verify",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/settings/verify.vue")
  },
  {
    name: "shops-category",
    path: "/shops/:category()",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/shops/[category].vue")
  },
  {
    name: "shops-spaceden-christmas-decorative-items",
    path: "/shops/spaceden-christmas-decorative-items",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/shops/spaceden-christmas-decorative-items.vue")
  },
  {
    name: "shops-spaceden-lunar-new-year-decorative-items",
    path: "/shops/spaceden-lunar-new-year-decorative-items",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/shops/spaceden-lunar-new-year-decorative-items.vue")
  },
  {
    name: "space-box-itemId",
    path: "/space-box/:itemId()",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/space-box/[itemId].vue")
  },
  {
    name: "staking",
    path: "/staking",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/staking.vue")
  },
  {
    name: "team",
    path: "/team",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/team.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/terms.vue")
  },
  {
    name: "testnet",
    path: "/testnet",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/testnet.vue")
  },
  {
    name: "tokenomics",
    path: "/tokenomics",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/tokenomics/index.vue")
  },
  {
    name: "tokenomics-list",
    path: "/tokenomics/list",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/tokenomics/list.vue")
  },
  {
    name: "wiki-enemy-name",
    path: "/wiki/enemy/:name()",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/wiki/enemy/[name].vue")
  },
  {
    name: "wiki-enemy",
    path: "/wiki/enemy",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/wiki/enemy/index.vue")
  },
  {
    name: "wiki-playermon-name",
    path: "/wiki/playermon/:name()",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/wiki/playermon/[name].vue")
  },
  {
    name: "wiki-playermon",
    path: "/wiki/playermon",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/wiki/playermon/index.vue")
  },
  {
    name: "wiki-spaceden-decorative-items",
    path: "/wiki/spaceden-decorative-items",
    component: () => import("/home/runner/work/playermon-frontend-nuxt/playermon-frontend-nuxt/pages/wiki/spaceden-decorative-items.vue")
  }
]